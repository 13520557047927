@import '../styleVariables.scss';

.loadingSpinner {
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 10000;
}

.outerGrid {
  max-width: $maxWidth;
  margin: 0 auto !important;
  width: 100% !important;
}

.textAlignLeft {
  text-align: left !important;
}

.textAlignCenter {
  text-align: center !important;
}

.textAlignRight {
  text-align: right !important;
}

.smallSpacer {
  height: $spacer;
}

.spacer {
  height: 4rem;
}

.spinner {
  border-top: $spacer/2 solid transparent;
  border-right: $spacer/2 solid $primary;
  border-left: $spacer/2 solid $deepdark;
  border-bottom: $spacer/2 solid transparent;
  border-radius: 50%;
  width: $spacer*3;
  height: $spacer*3;
  animation: spinnerRotate 1000ms infinite linear;
  position: fixed;
  bottom: $spacer * 2;
  left: $spacer * 2;
}

@keyframes spinnerRotate {
  100% {
    transform: rotate(360deg)
  }
}
