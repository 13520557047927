@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700");
:export {
  light: #2e8ffa;
  primary: #2e8ffa;
  secondary: #304060;
  success: #51C47C;
  error: #E33E3E;
  warning: #EECA12;
  info: #2e8ffa;
  deepdark: #002544;
  brightPrimary: #e4eff9;
  brightPrimaryText: #304060;
  brightYellow: #ffe4b5;
  brightRed: #ffd5d5;
  brightGreen: #c5ffe7;
  bodyBackground: #fff;
  menuHeight: 69px;
}

* {
  box-sizing: border-box !important;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html, body {
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif !important;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

a {
  text-decoration: none;
  color: #2e8ffa;
}

p, li {
  margin: 0;
  margin-bottom: 1rem;
}

strong, b {
  font-weight: bold;
}

select,
textarea,
input {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}