@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700');

// brand colors
$primary: #2e8ffa;
$primary-text: #fff;

$secondary: #304060;
$secondary-text: #fff;

$bright-yellow: #ffe4b5;
$bright-green: #c5ffe7;
$bright-red: #ffd5d5;
$bodyBackground: #fff;

// text colors
$bright-text: #fff;
$dark-text: #333;
$grey-text: #666;
$lightgrey-text: #ddd;

// complimentary colors
$success: #51C47C;
$danger: #E33E3E;
$warning: #EECA12;
$info: #e4eff9;
$bright-primary: #e4eff9;
$bright-primary-text: $secondary;
$deepdark: #002544;

// measurements
$font-size: 16px;
$spacer: 16px;
$maxWidth: 1140px;
$menuHeight: 69px;
$font-family-header: 'Source Sans Pro', sans-serif;
$font-family-body: 'Roboto', sans-serif;


:export {
  light: $primary;
  primary: $primary;
  secondary: $secondary;
  success: $success;
  error: $danger;
  warning: $warning;
  info: $primary;
  deepdark: $deepdark;
  brightPrimary: $bright-primary;
  brightPrimaryText: $bright-primary-text;
  brightYellow: $bright-yellow;
  brightRed: $bright-red;
  brightGreen: $bright-green;
  bodyBackground: $bodyBackground;
  menuHeight: $menuHeight;
}
