@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700");
:export {
  light: #2e8ffa;
  primary: #2e8ffa;
  secondary: #304060;
  success: #51C47C;
  error: #E33E3E;
  warning: #EECA12;
  info: #2e8ffa;
  deepdark: #002544;
  brightPrimary: #e4eff9;
  brightPrimaryText: #304060;
  brightYellow: #ffe4b5;
  brightRed: #ffd5d5;
  brightGreen: #c5ffe7;
  bodyBackground: #fff;
  menuHeight: 69px;
}

.loadingSpinner {
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 10000;
}

.outerGrid {
  max-width: 1140px;
  margin: 0 auto !important;
  width: 100% !important;
}

.textAlignLeft {
  text-align: left !important;
}

.textAlignCenter {
  text-align: center !important;
}

.textAlignRight {
  text-align: right !important;
}

.smallSpacer {
  height: 16px;
}

.spacer {
  height: 4rem;
}

.spinner {
  border-top: 8px solid transparent;
  border-right: 8px solid #2e8ffa;
  border-left: 8px solid #002544;
  border-bottom: 8px solid transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spinnerRotate 1000ms infinite linear;
  position: fixed;
  bottom: 32px;
  left: 32px;
}

@keyframes spinnerRotate {
  100% {
    transform: rotate(360deg);
  }
}