@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700|Source+Sans+Pro:300,400,700);
* {
  box-sizing: border-box !important;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html, body {
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif !important;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

a {
  text-decoration: none;
  color: #2e8ffa;
}

p, li {
  margin: 0;
  margin-bottom: 1rem;
}

strong, b {
  font-weight: bold;
}

select,
textarea,
input {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.General_loadingSpinner__1qZaE {
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 10000;
}

.General_outerGrid__2e711 {
  max-width: 1140px;
  margin: 0 auto !important;
  width: 100% !important;
}

.General_textAlignLeft__WS7XC {
  text-align: left !important;
}

.General_textAlignCenter__3J3PU {
  text-align: center !important;
}

.General_textAlignRight__1CyCo {
  text-align: right !important;
}

.General_smallSpacer__26Wn7 {
  height: 16px;
}

.General_spacer__1n8Qp {
  height: 4rem;
}

.General_spinner__2VSeW {
  border-top: 8px solid transparent;
  border-right: 8px solid #2e8ffa;
  border-left: 8px solid #002544;
  border-bottom: 8px solid transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: General_spinnerRotate__3S0gM 1000ms infinite linear;
  position: fixed;
  bottom: 32px;
  left: 32px;
}

@keyframes General_spinnerRotate__3S0gM {
  100% {
    transform: rotate(360deg);
  }
}
